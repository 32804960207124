import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { otpVerification } from "../redux/actions/adminActions";
import { data } from "../Components/Layout/elements/ChartUsers";
import { toast } from "react-toastify";

export default function LoginOtp() {

  const location=useLocation()
  const navigate=useNavigate()
  const dispatch=useDispatch()

  const [data,setData] = useState({
    otp:"",
    adminId: location?.state?.userId
  })

  const [error,setError] = useState("")

  const handlechange=(e)=>{
    setData((old)=>({...old,otp:e.target.value}))
    setError("")
  }

  const handleclick=async()=>{
   let isvalid=true

   if(!data?.otp){
    setError("Please enter OTP")
    isvalid=false
   }
   if(isvalid){
    try{
      let response = await dispatch(otpVerification(data))
      console.log(response)
      if(response?.payload?.data?.status_code === 200){
        navigate("/change-password",{state:{
          id:location?.state?.userId
        }})
      }else{
        toast.error("Incorrect OTP")
      }
    }catch(error){
      console.log(error)
    }
   }
   
  }
  
  return (
    <div>
      <Container fluid>
        <Row>
          <Col lg={12}>
            <div className="logo">
              <img src={require("../Assets/Images/Logo.svg").default} />
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="box-center">
          <Col lg={12} className="m-auto">
            <div className="login-cmn-box">
              <div className="login-box-inner-wrap">
                <h2 className="text-center">Enter OTP</h2>
                <Form>
                  <div className="form-set">
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>Enter OTP</Form.Label>
                      <Form.Control type="password" name="otp" value={data.otp} onChange={handlechange} placeholder="Type Here" />
                      <Form.Label className="text-danger">{error}</Form.Label>
                    </Form.Group>
                  </div>
                  <div
                  onClick={handleclick}
                    // to="/change-password"
                    variant="primary"
                    type="submit"
                    className="submit"
                  >
                    Change password
                    </div>
                  
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
