import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);



export default function Orders({genderPercentage}) {
const data = {
  labels: [`Male ${genderPercentage?.malePercentage}%`,`Female ${genderPercentage?.femalePercentage}%`,`Others ${genderPercentage?.otherPercentage}%`],
  datasets: [
    {
      label: "# of Votes",
      data: [genderPercentage?.malePercentage, genderPercentage?.femalePercentage, genderPercentage?.otherPercentage],
      backgroundColor: ["#979797", "#B9B5AB" ,"	#808080"],

      borderWidth: 1,
    },
  ],
};



  return (
    <>
    <Doughnut className="gender-chart" data={data} />
    </>
  )
}
