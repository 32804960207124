import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../Components/Layout/Layout";
import { Figure } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { differenceInYears } from 'date-fns';


import {
  banorUnban,
  deleteUser,
  userDetails,
} from "../redux/actions/adminActions";
import logo from "../Assets/Images/Logo.svg";
import { toast } from "react-toastify";
import moment from "moment";

export default function UserProfile() {
  const navigate = useNavigate();
  const [DOB, setDOB] = useState("");
  const [profileData, setProfileData] = useState(null);
  const location = useLocation();
  const [path, setPath] = useState();

  const [show, setshow] = useState(false);
  const [showdel,setshowdel] = useState(false);

  const dispatch = useDispatch();
  console.log(location?.state?.path);

  useEffect(() => {
    setPath(location?.state?.path);
  }, [location?.state?.path]);

  useEffect(() => {
    const fetchdata = async () => {
      try {
        let data = await dispatch(userDetails({ id: location?.state?.id }));
        setProfileData(data?.payload?.message);
      } catch (error) {
        console.log(error);
      }
    };
    fetchdata();
  }, [location?.state?.id]);

  const handledelete = async () => {
    try {
      let data = await dispatch(deleteUser({ id: location?.state?.id }));
      console.log(data);
      if (data?.payload?.status_code === 200) {
        toast.success(data?.payload?.message);
        setshowdel(false);
        navigate("/userManagement");
      } else {
        toast.error(data?.payload?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleclick = async () => {
    setshow(true);
  };

  const handledelclick = async () => {
    setshowdel(true);
  }

  const handleactivation = async () => {
    try {
      let data = await dispatch(
        banorUnban({
          id: location.state.id,
          action: "ban",
        })
      );
      console.log(data);
      if (data?.payload?.status_code === 200) {
        toast.success(data?.payload?.message);
        setshow(false);
      } else {
        toast.error(data?.payload?.message);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(()=>{

    let date = new Date()
    const formattedDate = moment(date).format('YYYY-MM-DD');

    setDOB(formattedDate?.slice(0,4) - profileData?.dob?.slice(5,10))
  },[profileData])

  console.log(profileData)

  const dob = profileData?.dob;
  let age = "";
  if (dob) {
    // Parse the date string to a Date object
    const dobDate = new Date(dob);
    
    // Calculate age based on the date of birth
    const yearsDiff = differenceInYears(new Date(), dobDate);
    age = Math.abs(yearsDiff);
  }

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <div>
            <h2>Customer Profile</h2>
          </div>
        </div>
        <hr />
      </div>

      <Container fluid>
        <div className="product-cmn-tab">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link
                  to="/userManagement"
                  // to={path}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="height-set px-4">
        <Row>
          <Col lg={12}>
            <Row>
              <Col lg={8}>
                <div className="user-profile-main">
                  <Row>
                    <Col lg={6}>
                      <Figure className="d-flex">
                        <div>
                          <Figure.Image
                            width={171}
                            height={180}
                            alt="171x180"
                            src={
                              profileData?.profile_image
                                ? `https://3ff-api.bosselt.com/${profileData?.profile_image}`
                                : logo
                            }
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <Figure.Caption>
                            <h2>
                              {profileData?.full_name}, {age}
                            </h2>
                            <p>{profileData?.email}</p>
                            <p>
                              {profileData?.gender === 1 ? "Male" : "Female"}
                            </p>
                            <p>
                              Looking For -{" "}
                              {profileData?.interestedIn === 0
                                ? "Both"
                                : profileData?.interestedIn === 1
                                ? "Men"
                                : "Women"}
                            </p>
                          </Figure.Caption>
                        </div>
                      </Figure>
                    </Col>
                  </Row>
                </div>
                <div className="user-profile-main px-3 py-4 mt-4">
                  <Row>
                    <Col lg={12}>
                      <div className="property-main mt-0">
                        <h2 className="property">About</h2>

                        <p>{profileData?.about}</p>

                        <h2 className="property mt-4">Interests</h2>
                        <div className="interest-sec">
                          <ul>
                            {profileData?.into?.map((x) => (
                              <li>{x?.name}</li>
                            ))}
                            {/* <li>Football</li>
                            <li>Skateboarding</li>
                            <li>Pool</li>
                            <li>Basketball</li>
                            <li>Golf</li> */}
                          </ul>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="user-profile-main mt-4">
                  <h2 className="property">Posts</h2>
                  <div className="post-inner">
                    <Row>
                      {profileData?.pictures?.map((x) => {
                        return (
                          <Col lg={4}>
                            <div className="post-box">
                              <img
                                src={`https://3ff-api.bosselt.com/${x?.pictures}`}
                              />
                            </div>
                          </Col>
                        );
                      })}
                    </Row>
                  </div>
                </div>
              </Col>
              <Col lg={4}>
                <Row>
                  <Col lg={12}>
                    <div className="user-profile-main px-3 py-4 h-100">
                      <Row>
                        <Col lg={12}>
                          <div className="property-main mt-0">
                            <h2 className="property">Suspend account</h2>
                            <Figure.Caption className="m-0 mt-4">
                              <p>
                                You will not be able to receive messages,
                                notifications for up to 24hours.
                              </p>
                              <div class="dlt-ac-btn mt-4">
                                <button type="button" onClick={handleclick}>
                                  Suspend Account
                                </button>
                              </div>
                            </Figure.Caption>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col lg={12} className="mt-4">
                    <div className="user-profile-main px-3 py-4 h-100">
                      <Row>
                        <Col lg={12}>
                          <div className="property-main mt-0">
                            <h2 className="property">Delete Account</h2>
                            <Figure.Caption className="m-0 mt-4">
                              <p>
                                Once you delete your account, you can not
                                retrieve the account. Please be certain.
                              </p>
                              <div class="dlt-ac-btn mt-4">
                                <button type="button" onClick={handledelclick}>
                                  Delete Account
                                </button>
                              </div>
                            </Figure.Caption>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col lg={12} className="mt-4">
                    <div className="user-profile-main px-3 py-4 h-100">
                      <Row>
                        <Col lg={12}>
                          <div className="property-main mt-0">
                            <h2 className="property pb-4">ACTIVITY</h2>
                            <div className="product-action-inner">
                              {profileData?.banStatus === 0 ? (
                                <>
                                  <h3>Account Suspended on</h3>
                                  <p>
                                    {profileData?.updatedAt.slice(0, 10)}
                                    {/* <span>13:32</span> */}
                                  </p>
                                </>
                              ) : (
                                <>
                                  {" "}
                                  <h3>Account Activated on</h3>
                                  <p>
                                    {profileData?.updatedAt?.slice(0, 10)}
                                    {/* <span>13:32</span> */}
                                  </p>
                                </>
                              )}
                            </div>
                            <div className="product-action-inner">
                              {/* <h3>[Admin Name] remove Verification Badge</h3>
                              <p>
                                01/01/2023 <span>13:32</span>
                              </p> */}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <Modal show={showdel} className="delete-popup">
        <Modal.Body>
          <h2 className="are-sure-text">
            Are you sure you want to Delete this Account
          </h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
        <Button className="theme-btn" onClick={handledelete}>
            Yes
          </Button>
          <Button className="theme-btn" onClick={() => setshowdel(false)}>
            No
          </Button>       
        </Modal.Footer>
      </Modal>

      <Modal show={show} className="delete-popup">
        <Modal.Body>
          <h2 className="are-sure-text">
            Are you sure you want to suspend this Account
          </h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
        <Button className="theme-btn" onClick={handleactivation}>
            Yes
          </Button>
          <Button className="theme-btn" onClick={() => setshow(false)}>
            No
          </Button>       
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}
