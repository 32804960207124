import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { getsupportchat } from "../redux/actions/adminActions";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import logo from "../Assets/Images/Logo.svg";
import { sendsuppmessage } from "../redux/actions/adminActions";
import { handleSupportStatus } from "../redux/actions/adminActions";
import { toast } from "react-toastify";

import { io } from "socket.io-client";

import SocketServices from "../SocketServices";
const newcconnection = io(process.env.REACT_APP_FILE_BASE_URL);

export default function SupportChat() {
  const dispatch = useDispatch();
  const location = useLocation();
  console.log(location);

  const [message, setmessage] = useState("");

  const [userdetails, setuserdetails] = useState([]);
  const [flag, setflag] = useState(true);

  const [chats, setchats] = useState([]);
  console.log(chats);

  const chatContainerRef = useRef(null);

  useEffect(() => {
    const fetchdata = async (id) => {
      try {
        const data = await dispatch(
          getsupportchat({ id: location?.state?.id })
        );

        setuserdetails(data?.payload?.data?.data?.userdetails);
        setchats(data?.payload?.data?.data?.chats);
      } catch (error) {
        console.log(error);
      }
    };
    fetchdata();
  }, [flag]);

  //   useEffect(()=>{
  //     console.log(newcconnection,"jjjjjjjjjjjjjjjjjjjjjjjjjjjjj")
  //     if(newcconnection.connected){
  //       console.log("jjjjjjjjjjjjjjjjjjjjjjjjjjjjj")

  // newcconnection.on(location?.state?.id,()=>{
  //   getsupportchat({ id: location?.state?.id })
  // })
  //     }
  //   },[location?.state?.id])

  newcconnection.on(location?.state?.id, async () => {
    console.log(
      location?.state?.id,
      newcconnection,
      "location?.state?.idlocation?.state?.id"
    );
    const data = await dispatch(getsupportchat({ id: location?.state?.id }));
    console.log(data);
    setuserdetails(data?.payload?.data?.data?.userdetails);
    setchats(data?.payload?.data?.data?.chats);
  });

  // console.log(chats?.support_id,"iidiiddi")

  const fetchsupportmess = async () => {
    try {
      const response = await dispatch(
        sendsuppmessage({
          message: message,
          support_id: location?.state?.id,
          sender_type: 0,
        })
      );

      if (response?.payload?.data?.success) {
        setmessage("");
        setflag(!flag);
        scrollToBottom();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const scrollToBottom = () => {
    // Scroll chat container to bottom
    chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
  };

  const handlesupportaction = async (e) => {
    try {
      let data = await dispatch(
        handleSupportStatus({
          id: location?.state?.id,
          action: e.target.value,
        })
      );
      console.log(data);
      if (data?.payload?.data?.status_code === 200) {
        toast.success(data?.payload?.data?.message);
      } else {
        toast.error(data?.payload?.data?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlemessageChange = (e) => {
    setmessage(e.target.value);
  };

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <div>
            <h2>Support Chat</h2>
          </div>
          <div></div>
        </div>
        <hr />
      </div>

      <Container fluid>
        <div className="product-cmn-tab">
          <Row>
            <Col lg={12}>
              <div className="product-tab-left">
                <Link to="/support">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid>
        <Row>
          <Col lg={8}>
            <div className="support-chat-box-main">
              <div className="support-inner">
                <h2>Chat</h2>
                <div className="support-msg-box" ref={chatContainerRef}>
                  {chats?.map((x) => {
                    return x?.docModel == "User" ? (
                      <div className="user-box">
                        <div className="user-top-hdng">
                          <img
                            src={
                              userdetails?.userimage
                                ? `https://3ff-api.bosselt.com/${userdetails?.userimage}`
                                : logo
                            }
                          />
                          <h3>{userdetails.username}</h3>
                          <h4>
                            {new Date(x?.updatedAt)
                              .toLocaleTimeString()
                              .slice(0, 5)}
                          </h4>
                        </div>
                        <div className="user-msg-box">
                          <p>{x?.message}</p>
                        </div>
                      </div>
                    ) : (
                      <div className="right-side">
                        <div className="user-box ">
                          <div className="user-top-hdng">
                            <h4>
                              {new Date(x?.updatedAt)
                                .toLocaleTimeString()
                                .slice(0, 5)}
                            </h4>
                            <h3>You</h3>
                            <img src={logo} />
                          </div>
                          <div className="user-msg-box">
                            <p>{x?.message}</p>
                          </div>
                        </div>
                      </div>
                    );
                  })}

                  {/* {chats?.map((x)=>{
                    return (
                      x?.docModel == "Admin" ? 
                       :null
                    )
                  })} */}
                </div>
              </div>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  fetchsupportmess();
                }}
              >
                <div class="send-box">
                  <div class="mb-3 input-group">
                    <input
                      placeholder="Type your message here..."
                      aria-label="send"
                      aria-describedby="basic-addon2"
                      name="message"
                      type="text"
                      class="send-feild form-control"
                      onChange={handlemessageChange}
                      value={message}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                          fetchsupportmess();
                        }
                      }}
                    />
                    <button
                      class="input-group-text"
                      id="basic-addon2"
                      type="button"
                      onClick={fetchsupportmess}
                    >
                      <img
                        src={require("../Assets/Images/toggle.svg").default}
                      />
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </Col>
          <Col lg={4}>
            {" "}
            <div className="customer-form-new px-4 pb-4">
              <h2 className="px-0">STATUS</h2>
              <Form.Select
                aria-label="Default select example"
                onChange={handlesupportaction}
              >
                <option value="0">OPEN </option>
                <option value="1">CLOSE</option>
              </Form.Select>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}
