import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Chart from "react-apexcharts";
import ReactApexChart from "react-apexcharts";
import Users, { data } from "../Components/Layout/elements/ChartUsers";
import Orders from "../Components/Layout/elements/ChartOrders";
import { dashboard } from "../redux/actions/adminActions";
import { useDispatch } from "react-redux";

export default function Dashboard() {
  const dispatch = useDispatch();

  const [alldata, setalldata] = useState(null);
  console.log(alldata);

  useEffect(() => {
    const fetchdata = async () => {
      try {
        const data = await dispatch(dashboard());
        console.log(data);

        setalldata(data?.payload?.data?.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchdata();
  }, []);

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>Dashboard</h2>
        </div>
      </div>
      <Container fluid>
        <div className="dashboard-items">
          <Row>
            <Col xxl={12} xl={12} lg={12}>
              <div className="dashbox-inner-wrap">
                <Row>
                  <Col xxl={3} xl={3} lg={3}>
                    <div className="dash-inner-boxes">
                      <h6>Total Users </h6>

                      <h4>{alldata?.totalUsers}</h4>
                      <p>
                        {alldata?.averageUsersPerDay} - Average users per day{" "}
                        {alldata?.newRegistration}
                      </p>
                    </div>
                  </Col>
                  {/* <Col xxl={3} xl={3} lg={3}>
                    <div className="dash-inner-boxes">
                      <h6>Total Matches </h6>
                      <h4>30 Millions</h4>
                      <p>1000 - Average users per day</p>
                    </div>
                  </Col> */}
                </Row>
              </div>
            </Col>
          </Row>

          <Row className="dash-bottom mb-4">
            <Col xxl={6} xl={6} lg={6} className="mb-4">
              <div className="dash-graph">
                <h6>Total Users</h6>
                <Users activeUsersRatio={alldata?.activeUsersRatio} />
              </div>
            </Col>
            <Col xxl={6} xl={6} lg={6}>
              <div className="dash-graph ">
                <h6>Total Users</h6>
                <Orders genderPercentage={alldata?.genderPercentage} />
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </Layout>
  );
}
