import React from "react";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import logo from "../Assets/Images/Logo.svg";
import { profileView } from "../redux/actions/adminActions";


export default function ProfileView() {

  const [userdata, setuserdata] = useState([]);
  

  const dispatch = useDispatch();

  const {id} = useParams();
  console.log(id);


  useEffect(() => {
    const fetchdata = async () => {
      try {
        const data = await dispatch(profileView({ id: id }));
        console.log(data);

       setuserdata(data?.payload?.data?.data || []);
  
      } catch (error) {
        console.log(error);
      }
    };
    fetchdata();
  }, []);

  return (
    <div>
      <div className="container">
        <div className="spotlift-profile-main">
          <div className="row">
            <div className="col-lg-12">
              <div className="profile-pic">
                <img src={ userdata?.profile_image
                                ? `https://3ff-api.bosselt.com/${userdata?.profile_image}`
                                : logo} />
                <div className="profile-desc">
                  {/* <img src={require("../Assets/Images/profile.svg").default} /> */}
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="btm-desc">
                <h3>{userdata?.full_name}</h3>
                {/* <p>170m away</p> */}
              </div>
            </div>
            <hr />
            <div className="col-lg-12">
              <div className="btm-desc">
                <h3>About</h3>
                <p>
                 {userdata?.about}
                </p>
              </div>
            </div>

            <hr />
            <div className="col-lg-12">
              {" "}
              <div className="interest-sec">
                <h2>Interests</h2>
                <div className="interest-images">
                  <ul>
                    {userdata?.into?.map((item) => (                     
                      <>
                    <li>{item?.name}</li>
                    {/* <li>Skateboarding</li>
                    <li>Pool</li>
                    <li>Basketball</li>
                    <li>Golf</li> */}
                    </>
                    ))}
                   
                  </ul>
                </div>
              </div>
            </div>
            <hr/>

            <div className="col-lg-12">
              <div className="gallery-images">
                <h3>Photos</h3>
                <div className="row">
                  {userdata?.pictures?.map((item) => (
                 <>
                  <div className="col-lg-4 mt-4">
                    <img src={`https://3ff-api.bosselt.com/${item?.pictures}`}/>
                  </div>
                  {/* <div className="col-lg-4 mt-4">
                    <img src={require("../Assets/Images/Rectangle 281.png")} />
                  </div>
                  <div className="col-lg-4 mt-4">
                    <img src={require("../Assets/Images/Rectangle 282.png")} />
                  </div>
                  <div className="col-lg-4 mt-4">
                    <img src={require("../Assets/Images/Rectangle 283.png")} />
                  </div>
                  <div className="col-lg-4 mt-4">
                    <img src={require("../Assets/Images/Rectangle 285.png")} />
                  </div> */}
                  </>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
