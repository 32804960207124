import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Await, Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetPassword } from "../redux/actions/adminActions";
import { toast } from "react-toastify";

export default function ForgotPasswordTwo() {

  const location=useLocation()
  const navigate=useNavigate()
  const dispatch=useDispatch()
  const [forgetPass,setforgetPass] = useState({
    newPassword:"",
    confirmPassword:"",
    adminId:location?.state?.id
  })

  const [error,setError]=useState({
    newPassworderr:"",
    confirmPassworderr:"",
  })

  const handlecahnge=(e)=>{
    setforgetPass((old)=>({...old,[e.target.name]:e.target.value}))
    setError((old)=>({...old,[`${e.target.name}err`]:""}))

  }

  const handleclick=async()=>{
    let err={
      newPassworderr:"",
      confirmPassworderr:"",

    }
    let isvalid=true
    if(!forgetPass.newPassword){
      err.newPassworderr="Enter new Password"
      isvalid=false
    }
    if(!forgetPass.confirmPassword){
      err.confirmPassworderr="Enter confirm Password"
      isvalid=false
    }
    if(!isvalid){
      setError(err)
    }
    if(isvalid){
      try{
        let data=await dispatch(resetPassword(forgetPass))
        console.log(data)
        if(data?.payload?.status_code ===200 ){
          toast.success(data?.payload?.message)
          navigate("/")
        }else{
          toast.error("Password must be same")
        }
      }catch(error){
        console.log(error)
      }

    }
    
    
    
  }


  return (
    <div>
      <Container fluid>
        <Row>
          <Col lg={12}>
            <div className="logo">
              <img src={require("../Assets/Images/Logo.svg").default} />
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="box-center">
          <Col lg={12} className="m-auto">
            <div className="login-cmn-box">
              <div className="login-box-inner-wrap">
                <h2 className="text-center">Enter New Password</h2>
                <Form>
                  <div className="form-set">
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>Enter Password</Form.Label>
                      <Form.Control type="password" name="newPassword" value={forgetPass.newPassword} onChange={handlecahnge}  placeholder="Type Here" />
                      <Form.Label className="text-danger">{error?.newPassworderr}</Form.Label>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>Confirm Password</Form.Label>
                      <Form.Control type="password" name="confirmPassword" value={forgetPass.confirmPassword} onChange={handlecahnge} placeholder="Type Here" />
                      <Form.Label className="text-danger">{error?.confirmPassworderr}</Form.Label>
                    </Form.Group>
                  </div>
                  <div
                    // to="/"
                    onClick={handleclick}
                    variant="primary"
                    type="submit"
                    className="submit"
                  >
                    Log In
                  </div>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
