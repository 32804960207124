import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { useDispatch, useSelector } from "react-redux";

import logo from "../Assets/Images/Logo.svg";
import * as XLSX from "xlsx";
import Pagination from "../Components/Layout/elements/Pagination";
import { toast } from "react-toastify";
import {
  alluser,
  alluserpgnumber,
  allusertpath,
} from "../redux/reducers/userMgmt";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import moment from "moment";
import { Button } from "react-bootstrap";
import { banorUnban, userMnagement } from "../redux/actions/adminActions";
import { changeSectionValueFormat } from "@mui/x-date-pickers/internals/hooks/useField/useField.utils";

export default function UserManagement() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.usermanagementdata.allData);

  const data1 = useSelector((state) => state.usermanagementdata.banData);

  // const pageNumberFromRedux = useSelector(
  //   (state) => state.usermgmtData.Alluserpagenumber
  // );
  // const pathFromRedux = useSelector((state) => state.usermgmtData.alluserpath);

  // const location = useLocation();

  // useEffect(() => {
  //   dispatch(allusertpath(location?.pathname));
  // }, [location?.pathname]);

  // const userLength = useSelector((state) => state.usermgmtData.alluserlength);
  // const banuserLength = useSelector(
  //   (state) => state.usermgmtData.banneduserlength
  // );
  // const activeuserLength = useSelector(
  //   (state) => state.usermgmtData.activeuserlength
  // );
  const navigate = useNavigate();
  // const [AllUsers, setAllUsers] = useState(null);
  const [flag, setFlag] = useState(true);
  const [pagination, setpagination] = useState(null);
  const [filterDataByDate, setFilterDataByDate] = useState(null);
  const [range, setRange] = useState(true);
  const [userMgmtParams, setUserMgmtParams] = useState({
    page: 1,
    search: "",
    limit: "10",
  });
  const [dateRange, setDateRange] = useState([
    {
      startDate: null,
      endDate: null,
      key: "selection",
    },
  ]);
  const [pickerVisible, setPickerVisible] = useState(false);
  const [initialRender, setInitialRender] = useState(true);

  const handleSelect = (ranges) => {
    setDateRange([ranges?.selection]);
  };

  //======================================= Date-range //==================================

  const startformattedDate = moment(dateRange[0]?.startDate)?.format(
    "YYYY-MM-DD");
  const endformattedDate = moment(dateRange[0]?.endDate)?.format("YYYY-MM-DD");

  useEffect(() => {
    dispatch(userMnagement(userMgmtParams));
  }, [userMgmtParams, flag]);

  useEffect(() => {
    setpagination(
      Math.ceil(
        (userMgmtParams?.search
          ? data?.data?.totalCount
          : data?.data?.totalUser) / data?.data?.limit
      )
    );
  }, [data]);

  // useEffect(() => {
  //   let fetchdata = async () => {
  //     if (!initialRender) {
  //       const filterdata = data?.data?.users?.filter((x) => {
  //         return (
  //           x?.createdAt?.slice(0, 10) >= startformattedDate &&
  //           x?.createdAt?.slice(0, 10) <= endformattedDate
  //         );
  //       });

  //       setFilterDataByDate(filterdata);
  //     }
  //     setInitialRender(false);
  //   };
  //   fetchdata();
  // }, [range]);
  useEffect(() => {
    let fetchdata = async () => {
      if (!initialRender) {
        const filterdata = data?.data?.users?.filter((x) => {
          return (
            x?.createdAt?.slice(0, 10) >= startformattedDate &&
            x?.createdAt?.slice(0, 10) <= endformattedDate
          );
        });

        setFilterDataByDate(filterdata);
      }
      setInitialRender(false);
    };
    fetchdata();
  }, [range, startformattedDate, endformattedDate]);

  const handleDateFieldClick = () => {
    setPickerVisible(true);
  };
  const closerange = () => {
    setPickerVisible(false);
    setRange(!range);
  };

  const getStartDateText = () => {
    return dateRange[0]?.startDate
      ? dateRange[0]?.startDate?.toLocaleDateString()
      : "";
  };

  const todayrange = () => {
    const currentDate = new Date();

    setDateRange([
      {
        startDate: currentDate,
        endDate: currentDate,
        key: "selection",
      },
    ]);
    setRange(!range);
    setPickerVisible(false);
  };

  const yesterdayrange = () => {
    const currentDate = new Date();
    const yesterday = new Date(currentDate);
    yesterday.setDate(currentDate.getDate() - 1);

    setDateRange([
      {
        startDate: yesterday,
        endDate: yesterday,
        key: "selection",
      },
    ]);
    setRange(!range);
    setPickerVisible(false);
  };

  const sevenDaysBefore = () => {
    const currentDate = new Date();
    const sevendays = new Date(currentDate);
    sevendays.setDate(currentDate.getDate() - 7);

    setDateRange([
      {
        startDate: sevendays,
        endDate: currentDate,
        key: "selection",
      },
    ]);
    setRange(!range);
    setPickerVisible(false);
  };

  // ========================Pagination=============================================

  const handlePages = (page) => {
    // dispatch(alluserpgnumber(page));

    setUserMgmtParams((old) => ({ ...old, page: page }));
  };

  // const fetchDataAndExportToExcel = async () => {
  //   try {
  //     const response = await dispatch(userMnagement({
  //       page: "",
  //       search: "",
  //       limit: "",
  //     }));
  //     console.log(response);

  //     const allData = response?.payload?.data?.users;

  //     const ws = XLSX.utils.json_to_sheet(allData);

  //     const wb = XLSX.utils.book_new();
  //     XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

  //     XLSX.writeFile(wb, "exported_data.xlsx", {
  //       bookType: "xlsx",
  //       bookSST: false,
  //       type: "blob",
  //     });
  //   } catch (error) {
  //     console.error("Error fetching or exporting data:", error);
  //   }
  // };
  const fetchDataAndExportToExcel = async () => {
    try {
      const response = await dispatch(userMnagement(userMgmtParams));
      console.log(response);

      const allData = response?.payload?.data?.users;

      const ws = XLSX.utils.json_to_sheet(allData);

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

      XLSX.writeFile(wb, "exported_data.xlsx", {
        bookType: "xlsx",
        bookSST: false,
        type: "blob",
      });
    } catch (error) {
      console.error("Error fetching or exporting data:", error);
    }
  };

  const handlechange = (e) => {
    setUserMgmtParams((old) => ({ ...old, limit: e.target.value }));
  };

  const handlesearch = (e) => {
    setUserMgmtParams((old) => ({ ...old, search: e.target.value }));
  };

  const handlebanned = async (id, e) => {
    try {
      let data = await dispatch(
        banorUnban({
          id: id,
          action: e.target.value,
        })
      );
      console.log(data);
      if (data?.payload?.data?.status_code === 200) {
        toast.success(data?.payload?.message);
        setFlag(!flag);
      } else {
        toast.error(data?.payload?.message);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleclick = (id) => {
    navigate("/UserProfile", {
      state: {
        id: id,
        // path: pathFromRedux,
      },
    });
  };

  const handleClose = () => {
    setPickerVisible(false);
    // setDateRange(+("Select range"))
    setDateRange([
      {
        startDate: null,
        endDate: null,
        key: "selection",
      },
    ]);
  };

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>User Management </h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={3} md={3}>
                <Form.Group>
                  <Form.Control
                    type="search"
                    name="search"
                    value={userMgmtParams.search}
                    onChange={handlesearch}
                    placeholder="Search.."
                  />
                </Form.Group>
              </Col>
              <Col xxl={3} xl={3} lg={3} md={3}>
                <input
                  type="text"
                  value={`${getStartDateText()}     ${
                    dateRange[0]?.endDate?.toLocaleDateString() ||
                    "Select date range"
                  }`}
                  onClick={handleDateFieldClick}
                  readOnly
                />

                {pickerVisible && (
                  <div
                    className="date-picker"
                    style={{
                      width: "500px",
                      height: "500px",
                      overflow: "scroll",
                      position: "absolute",
                    }}
                  >
                    <div className="d-flex gap-3 justify-content-end m-2 date-range-filters">
                      <Button onClick={handleClose}>Close</Button>
                    </div>
                    <DateRange
                      ranges={dateRange}
                      onChange={handleSelect}
                      months={2}
                      direction="horizontal"
                    />
                    <div className="d-flex gap-3 justify-content-between date-range-filters">
                      <div className="d-flex gap-3">
                        <Button onClick={todayrange}>Today</Button>
                        <Button onClick={yesterdayrange}>Yesterday</Button>
                        <Button onClick={sevenDaysBefore}>before 7 days</Button>
                      </div>
                      <div>
                        <Button onClick={closerange}>Ok</Button>
                      </div>
                    </div>
                  </div>
                )}
              </Col>

              <Col
                xxl={6}
                xl={6}
                lg={6}
                md={6}
                className="d-flex justify-content-end align-items-center"
              >
                {/* <div className="cmn-btn">
                  <Link onClick={fetchDataAndExportToExcel}>Clear Range</Link>
                </div> */}

                <div className="cmn-btn">
                  <Link onClick={fetchDataAndExportToExcel}>DOWNLOAD</Link>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <hr />
        <Container fluid>
          <div className="product-tab">
            <ul>
              <li>
                <Link to="/UserManagement" className="active-tab">
                  ALL <span></span>
                </Link>
              </li>
              <li>
                <Link to="/temporarybanned">
                  Banned <span></span>
                </Link>
              </li>
              <li>
                <Link to="/active">
                  Active <span></span>
                </Link>
              </li>
            </ul>
          </div>
        </Container>
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">
                {" "}
                Showing {1 +
                  (userMgmtParams?.page - 1) * userMgmtParams?.limit}{" "}
                -{" "}
                {data?.data?.users?.length +
                  (userMgmtParams?.page - 1) * userMgmtParams.limit}{" "}
                of{" "}
                {userMgmtParams.search
                  ? data?.data?.totalCount
                  : data?.data?.totalUser}{" "}
                results
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  onChange={handlechange}
                  name="limit"
                  value={userMgmtParams.limit}
                  aria-label="Default select example"
                >
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Name</th>
                <th>Email </th>
                <th>Profile Picture</th>
                <th>Location</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {(filterDataByDate?.length >= 0
                ? filterDataByDate
                : data?.data?.users
              )?.map((item, index) => {
                const serialNumber =
                  (userMgmtParams?.page - 1) * userMgmtParams?.limit +
                  index +
                  1;
                return (
                  <tr>
                    <td>{serialNumber}</td>
                    <td
                      style={{ cursor: "pointer", fontWeight: "bolder" }}
                      onClick={() => handleclick(item?._id)}
                    >
                      {item?.full_name ? item?.full_name : "N/A"}
                    </td>
                    <td>{item?.email ? item?.email : "N/A"}</td>
                    <td className="user-img ">
                      <img
                        src={
                          item?.profile_image
                            ? `https://3ff-api.bosselt.com/${item?.profile_image}`
                            : logo
                        }
                      />
                    </td>
                    <td>{item?.address ? item?.address : "N/A"}</td>
                    <td>
                      {item?.status === 0
                        ? "In-active"
                        : item.banStatus === 1
                        ? "Active"
                        : "Suspend"}
                    </td>

                    <td className="select-box">
                      <Form.Select
                        value={item?.banStatus === 0 ? "ban" : "active"}
                        onChange={(e) => handlebanned(item._id, e)}
                        aria-label="Default select example"
                      >
                        <option value="ban">Suspend</option>
                        <option value="active">Active</option>
                      </Form.Select>
                    </td>
                  </tr>
                );
              })}
              <td></td>
              <td></td>
              <td></td>
              <td>{filterDataByDate?.length == 0 && "No data found"}</td>
            </tbody>
          </Table>
        </div>
      </Container>
      <Pagination pagination={pagination} paginationevent={handlePages} />
    </Layout>
  );
}
