import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { useDispatch } from "react-redux";
import {
  handleSupportStatus,
  supportchat,
  dowanloadcsv,
} from "../redux/actions/adminActions";
import { HandleThunkActionCreator } from "react-redux";
import { useNavigate } from "react-router-dom";
import Pagination from "../Components/Layout/elements/Pagination";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";

export default function Support() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [Alldata, setAlldata] = useState([]);
  // console.log(Alldata)
  const [pagination, setpagination] = useState(null);
  const [flag, setflag] = useState();
  const [supportparms, setsupportparms] = useState({
    page: 1,
    search: "",
    limit: "10",
  });
  useEffect(() => {
    const fetchdata = async () => {
      try {
        const data = await dispatch(supportchat(supportparms));
        console.log(data);

        setAlldata(data?.payload?.data?.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchdata();
  }, [supportparms, flag]);

  const fetchDataAndExportTocsv = async () => {
    try {
      const response = await dispatch(dowanloadcsv());
      console.log(response);

      const allData = response?.payload?.data?.data;
      console.log(allData);

      const ws = XLSX.utils.json_to_sheet(allData);

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

      XLSX.writeFile(wb, "exported_data.xlsx", {
        bookType: "xlsx",
        bookSST: false,
        type: "blob",
      });
    } catch (error) {
      console.error("Error fetching or exporting data:", error);
    }
  };

  useEffect(() => {
    setpagination(
      Math.ceil((supportparms?.search ? 1 : Alldata.total) / Alldata?.limit)
    );
  }, [Alldata]);

  const handlePages = (page) => {
    setsupportparms((old) => ({ ...old, page: page }));
  };

  const handlesearch = (e) => {
    setsupportparms((old) => ({ ...old, search: e.target.value }));
  };

  const handlesupportaction = async (id, e) => {
    try {
      let data = await dispatch(
        handleSupportStatus({
          id: id,
          status: e.target.value,
        })
      );
      console.log(data);
      if (data?.payload?.data?.status_code === 200) {
        toast.success(data?.payload?.data?.message);
        setflag(!flag);
      } else {
        toast.error(data?.payload?.data?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleclick = (id) => {
    navigate("/Supportchat", {
      state: {
        id: id,
      },
    });
  };

  console.log(
    Alldata?.supports,
    "Alldata?.supportsAlldata?.supportsAlldata?.supportsAlldata?.supports"
  );

  console.log(Alldata?.supports?._doc?.user_id?.status)

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>User Support</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Group>
                  <Form.Control
                    type="search"
                    placeholder="Search.."
                    name="search"
                    value={supportparms.search}
                    onChange={handlesearch}
                  />
                </Form.Group>
              </Col>

              <Col className="d-flex justify-content-end align-items-center">
                <div className="cmn-btn">
                  <Link onClick={fetchDataAndExportTocsv}>DOWNLOAD CSV</Link>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <hr />
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              {/* <div className="show-results">Showing 1 - 10 of 20 results</div> */}
              <div className="show-results">
                {" "}
                Showing {1 +
                  (supportparms?.page - 1) * supportparms?.limit} -{" "}
                {Alldata?.supports?.length +
                  (supportparms?.page - 1) * supportparms.limit}{" "}
                of {supportparms.search ? Alldata?.total : Alldata?.total}{" "}
                results
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select aria-label="Default select example">
                  <option>10</option>
                  <option value="1">11</option>
                  <option value="2">12</option>
                  <option value="3">13</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>S.No.</th>
                <th>Full Name</th>
                <th>Email</th>
                {/* <th>Contact numbers</th> */}
                <th>Message </th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {Alldata?.supports?.map((x, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td
                      style={{ cursor: "pointer", fontWeight: "bolder" }}
                      onClick={() => handleclick(x?.supportChat?.support_id)}
                    >
                      {x?._doc?.user_id?.full_name}
                      <br />
                      <span className="time">{}</span>
                    </td>
                    <td>
                      <Link to="/Supportchat">{x?._doc?.user_id?.email}</Link>
                    </td>
                    {/* <td>{x?.contact ? x?.contact : "N/A"}</td> */}
                    <td>{x?.supportChat?.message}</td>
                    <td>
                      {" "}
                      {x?._doc?.status == 0
                        ? "Inprogress"
                        : "Resolve"}
                    </td>

                    <td className="select-box">
                      <Form.Select
                        aria-label="Default select example"
                        value={x?._doc?.status}
                        // value={x?.status === 0 ? "Inprogress" : "Resolved"}
                        onChange={(e) => handlesupportaction(x?.supportChat?.support_id, e)}
                      >
                        <option value="1">Resolved</option>
                        <option value="0">In Progress</option>
                      </Form.Select>
                    </td>
                  </tr>
                );
              })}
              {/* <tr>
                <td>000001</td>
                <td>
                01/02/2023
                  <br />
                  <span className="time">12:03</span>
                </td>
                <td>
                  <Link to="/Supportchat">Johnsmith@gmail.com</Link>
                </td>
                <td>999999999</td>
                <td>
                  I’m trying to delete my account. Could you please help ...
                </td>
                <td>In Progress</td>
                <td className="select-box">
                  <Form.Select aria-label="Default select example">
                    <option>Resolved</option>
                    <option value="1">In Progress</option>
                  </Form.Select>
                </td>
              </tr>
              <tr>
                <td>000001</td>
                <td>
                  01/02/2023
                  <br />
                  <span className="time">12:03</span>
                </td>
                <td>
                  <Link to="/Supportchat">Johnsmith@gmail.com</Link>
                </td>
                <td>999999999</td>
                <td>
                  I’m trying to delete my account. Could you please help ...
                </td>
                <td>Resolved</td>
                <td className="select-box">
                  <Form.Select aria-label="Default select example">
                    <option>Resolved</option>
                    <option value="1">In Progress</option>
                  </Form.Select>
                </td>
              </tr>
              <tr>
                <td>000001</td>
                <td>
                  01/02/2023
                  <br />
                  <span className="time">12:03</span>
                </td>
                <td>
                  <Link to="/Supportchat">Johnsmith@gmail.com</Link>
                </td>
                <td>999999999</td>
                <td>
                  I’m trying to delete my account. Could you please help ...
                </td>
                <td>Resolved</td>
                <td className="select-box">
                  <Form.Select aria-label="Default select example">
                    <option>Resolved</option>
                    <option value="1">In Progress</option>
                  </Form.Select>
                </td>
              </tr>
              <tr>
                <td>000001</td>
                <td>
                  01/02/2023
                  <br />
                  <span className="time">12:03</span>
                </td>
                <td>
                  <Link to="/Supportchat">Johnsmith@gmail.com</Link>
                </td>
                <td>999999999</td>
                <td>
                  I’m trying to delete my account. Could you please help ...
                </td>
                <td>Resolved</td>
                <td className="select-box">
                  <Form.Select aria-label="Default select example">
                    <option>Resolved</option>
                    <option value="1">In Progress</option>
                  </Form.Select>
                </td>
              </tr>
              <tr>
                <td>000001</td>
                <td>
                  01/02/2023
                  <br />
                  <span className="time">12:03</span>
                </td>
                <td>
                  <Link to="/Supportchat">Johnsmith@gmail.com</Link>
                </td>
                <td>999999999</td>
                <td>
                  I’m trying to delete my account. Could you please help ...
                </td>
                <td>In Progress</td>
                <td className="select-box">
                  <Form.Select aria-label="Default select example">
                    <option>Resolved</option>
                    <option value="1">In Progress</option>
                  </Form.Select>
                </td>
              </tr>
              <tr>
                <td>000001</td>
                <td>
                  01/02/2023
                  <br />
                  <span className="time">12:03</span>
                </td>
                <td>
                  <Link to="/Supportchat">Johnsmith@gmail.com</Link>
                </td>
                <td>999999999</td>
                <td>
                  I’m trying to delete my account. Could you please help ...
                </td>
                <td>Resolved</td>
                <td className="select-box">
                  <Form.Select aria-label="Default select example">
                    <option>Resolved</option>
                    <option value="1">In Progress</option>
                  </Form.Select>
                </td>
              </tr>
              <tr>
                <td>000001</td>
                <td>
                  01/02/2023
                  <br />
                  <span className="time">12:03</span>
                </td>
                <td>
                  <Link to="/Supportchat">Johnsmith@gmail.com</Link>
                </td>
                <td>999999999</td>
                <td>
                  I’m trying to delete my account. Could you please help ...
                </td>
                <td>Resolved</td>
                <td className="select-box">
                  <Form.Select aria-label="Default select example">
                    <option>Resolved</option>
                    <option value="1">In Progress</option>
                  </Form.Select>
                </td>
              </tr> */}
            </tbody>
          </Table>
        </div>
      </Container>

      {/* <div className="next-btn-fix">
        <Link to="#">Next</Link>
      </div> */}

      <Pagination pagination={pagination} paginationevent={handlePages} />
    </Layout>
  );
}
