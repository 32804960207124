import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { forgetPassword } from "../redux/actions/adminActions";
import { toast } from "react-toastify";

export default function ForgotPassword() {
  const dispatch=useDispatch()
  const navigate=useNavigate()
  const [userEmail, setuserEmail] = useState({
    email: "",
  });
  const [error,setError] = useState("")

  const handlechange = (e) => {
    setuserEmail((old) => ({ ...old, email: e.target.value }));
    setError("")
  };

  const handleclick = async() => {

    let isvalid=true
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if(!userEmail?.email){
      setError("Please enter Email")
      isvalid=false
    }else if (!emailRegex.test(userEmail.email)) {
      setError("Please Enter Valid Email");
      isvalid = false;
    }
    if(isvalid){
      try{
        let data  = await dispatch(forgetPassword(userEmail))
        console.log(data)
        if(data?.payload?.data?.status_code == 200){
          toast.success(data?.payload?.data?.message)
          navigate("/otp",{state:{
            userId:data?.payload?.data?.data?._id 
          }})
        }else{
          toast.error(data?.payload?.message)
        }
      }catch(error){
        console.log(error)
      }

    }
    
    
  };

  return (
    <div>
      <Container fluid>
        <Row>
          <Col lg={12}>
            <div className="logo">
              <img src={require("../Assets/Images/Logo.svg").default} />
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="box-center">
          <Col lg={12} className="m-auto">
            <div className="login-cmn-box">
              <div className="login-box-inner-wrap">
                <h2 className="text-center">Forgot Password</h2>
                <Form>
                  <div className="form-set">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        value={userEmail.email}
                        onChange={handlechange}
                        placeholder="Type Here"
                      />
                       <Form.Label className="text-danger">{error}</Form.Label>
                    </Form.Group>
                  </div>
                  <div
                    onClick={handleclick}
                    // to="/otp"
                    variant="primary"
                    type="submit"
                    className="submit"
                  >
                    Send OTP
                  </div>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
