import { configureStore } from "@reduxjs/toolkit";
import { userMgmtSlice, userMnagementSlice } from "./reducers/userMgmt";


export const store = configureStore({
  reducer: {
    usermanagementdata:userMnagementSlice.reducer

  },
});
