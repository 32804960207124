import { createAsyncThunk } from "@reduxjs/toolkit";
import { adminapi } from "../../services/adminapi";

const ChangeUserPassword = createAsyncThunk(
  "ChangeUserPassword",
  async (details) => {
    const data = await adminapi.post("/changePassAdmin", details);
    return data;
  }
);

const logoutUser = createAsyncThunk("logoutUser", async () => {
  const { data } = await adminapi.post("/logOut");

  return data;
});

const userMnagement = createAsyncThunk("allusers", async (details) => {
  const { data } = await adminapi.get(
    `/getAllUsers?page=${details.page}&limit=${details.limit}&search=${details.search}`
  );

  return data;
});

const banorUnban = createAsyncThunk("banorUnban", async (details) => {
  const { data } = await adminapi.post(`/tempBanOrUnbanAccount`, details);

  return data;
});
const banoractiveListing = createAsyncThunk(
  "banoractiveListing",
  async (details) => {
    const { data } = await adminapi.get(
      `/banUnbanUsers?page=${details.page}&limit=${details.limit}&search=${details.search}&banStatus=${details.banStatus}`,
      details
    );

    return data;
  }
);
const userDetails = createAsyncThunk("userDetails", async (details) => {
  const { data } = await adminapi.get(`/getUserProfile?id=${details.id}`);

  return data;
});
const deleteUser = createAsyncThunk("deleteUser", async (details) => {
  const { data } = await adminapi.post(`/deleteUserAccount`,details);

  return data;
});
const forgetPassword = createAsyncThunk("forgetPassword", async (details) => {
  const  data  = await adminapi.post(`/forgotPassword`,details);

  return data;
});
const otpVerification = createAsyncThunk("otpVerification", async (details) => {
  const  data  = await adminapi.post(`/verifyOtp`,details);

  return data;
});
const resetPassword = createAsyncThunk("resetPassword", async (details) => {
  const  {data}  = await adminapi.post(`/resetPassword`,details);

  return data;
});


const profileView = createAsyncThunk(
"profileView",
async (details) => {
  const data = await adminapi.get(`/getUserDetailswithOutToken?user_id=${details?.id}`)
  console.log(data);

  return data;
}
)

const dashboard = createAsyncThunk(
  "dashboard",
  async (details) => {
    const data = await adminapi.get('/dashboard', details)
    console.log(data)

    return data;
  }
)

const supportchat = createAsyncThunk(
  "supportchat",
  async(details) => {
    const data = await adminapi.get(`/getSupports?page=${details?.page}&limit=${details.limit}&search=${details.search}`, details);
    console.log(data);

    return data;
  }
)

const getsupportchat = createAsyncThunk(
  "getsupportchat",
  async(details) => {
    const data = await adminapi.get(`/getSupportChat?id=${details.id}`)
    console.log(data);
    return data;
  })


  const handleSupportStatus = createAsyncThunk(
    "handleSupportStatus",

    async(details) => {
      const data = await adminapi.post('/changeSupportStatus',details)
      // console.log(data);

      return data;
    }
  )

  const dowanloadcsv = createAsyncThunk(
    "dowanloadcsv",

    async() => {
      const data = await adminapi.get('/getUsers');
      console.log(data);

      return data;
    }
  )

  const sendsuppmessage = createAsyncThunk(
    "sendsuppmessage",
    async(details) => {
      const data = await adminapi.post('/sendSupportMessage', details);
      console.log(data);


      return data;
    }
  )

  const Allreports = createAsyncThunk(
    "Allreports",
    async(details) => {
      const data = await adminapi.get(`/getBlocks?page=${details?.page}&limit=${details.limit}&search=${details.search}&status=${details.status}`);
     

      return data;
    }
  )

  const dowanreportUsers = createAsyncThunk(
    "dowanreportUsers",

    async() => {
      const data = await adminapi.get('/getAllBlocks');
      console.log(data);

      return data;
    }
  )

  const getBlockUserbyid = createAsyncThunk(
    "getBlockUserbyid",
    async(details) => {
      const data = await adminapi.get(`/getBlockById?id=${details.id}`)
      console.log(data);
      return data;
    })


    const CloseReport = createAsyncThunk(
      "CloseReport",
      async(details) => {
        const response = await adminapi.post('/closeBlock', details);
        console.log(response);

        return response;
      }
    )



export {
  ChangeUserPassword,
  logoutUser,
  userMnagement,
  banorUnban,
  banoractiveListing,
  userDetails,
  deleteUser,forgetPassword,otpVerification,resetPassword,
  profileView,
  dashboard,
  supportchat,
  handleSupportStatus,
  getsupportchat,
  dowanloadcsv,
  sendsuppmessage,
  Allreports,
  dowanreportUsers,
  getBlockUserbyid,
  CloseReport,

};
