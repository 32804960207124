import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);
export default function Users({activeUsersRatio}) {
  const data = {
    labels: [`Active ${activeUsersRatio?.active}%`, `Inactive ${activeUsersRatio?.inActive}%`],
    datasets: [
      {
        label: "# of Votes",
        data: [activeUsersRatio?.active, activeUsersRatio?.inActive],
        backgroundColor: ["#979797", "#B9B5AB"],
        borderWidth: 1,
      },
    ],
  };
  return (
      <>
  <Doughnut className="gender-chart" data={data} /> 
  </>
  )
}
