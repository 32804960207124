import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { banorUnban, banoractiveListing } from "../redux/actions/adminActions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import logo from "../Assets/Images/Logo.svg";
import Pagination from "../Components/Layout/elements/Pagination";
import * as XLSX from "xlsx";
import {
  activeuserlength,
  activeuserpgnumber,
  activeuserstpath,
} from "../redux/reducers/userMgmt";
import { Button } from "react-bootstrap";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import moment from "moment";

export default function Active() {
  const data1 = useSelector((state) => state.usermanagementdata.allData);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.usermanagementdata.banData);
  console.log(data);
  // const userLength = useSelector((state)=>state.usermgmtData.alluserlength)
  // const banuserLength = useSelector((state)=>state.usermgmtData.banneduserlength)
  // const activeuserLength = useSelector((state)=>state.usermgmtData.activeuserlength)
  // const pageNumberFromRedux=useSelector((state)=>state.usermgmtData.activeuserpagenumber)
  // const pathFromRedux=useSelector((state)=>state.usermgmtData.activeuserpath)
  // console.log(pathFromRedux)
  // const location = useLocation()

  // useEffect(()=>{
  //   dispatch(activeuserstpath(location?.pathname))

  // },[location?.pathname])

  const [flag, setFlag] = useState(true);

  // const dispatch = useDispatch();
  const [pagination, setpagination] = useState(null);
  const [range, setRange] = useState(true);
  const [filterDataByDate, setFilterDataByDate] = useState(null);
  const [userMgmtParams, setUserMgmtParams] = useState({
    page: "1",
    search: "",
    limit: "10",
    banStatus: "1",
  });
  // const [AllUsers, setAllUsers] = useState(null);

  const [dateRange, setDateRange] = useState([
    {
      startDate: null,
      endDate: null,
      key: "selection",
    },
  ]);

  const startformattedDate = moment(dateRange[0]?.startDate)?.format(
    "YYYY-MM-DD"
  );
  const endformattedDate = moment(dateRange[0]?.endDate)?.format("YYYY-MM-DD");

  const [pickerVisible, setPickerVisible] = useState(false);
  const [initialRender, setInitialRender] = useState(true);

  const handleSelect = (ranges) => {
    setDateRange([ranges?.selection]);
  };

  useEffect(() => {
    dispatch(banoractiveListing(userMgmtParams));
  }, [userMgmtParams, flag]);

  useEffect(() => {
    setpagination(
      Math.ceil(
        (userMgmtParams?.search ? 1 : data?.data?.totalUsers) /
          data?.data?.limit
      )
    );
  }, [data]);

  const handlePages = (page) => {
    // dispatch(activeuserpgnumber(page));

    setUserMgmtParams((old) => ({ ...old, page: page }));
  };

  const handlebanned = async (id, e) => {
    console.log(e.target.value);
    console.log(id);
    try {
      let data = await dispatch(
        banorUnban({
          id: id,
          action: e.target.value,
        })
      );
      console.log(data);
      if (data?.payload?.status_code === 200) {
        toast.success(data?.payload?.message);
        setFlag(!flag);
      } else {
        toast.error(data?.payload?.message);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handlechange = (e) => {
    setUserMgmtParams((old) => ({ ...old, limit: e.target.value }));
  };
  const handlesearch = (e) => {
    setUserMgmtParams((old) => ({ ...old, search: e.target.value }));
  };

  // const fetchDataAndExportToExcel = async () => {
  //   try {
  //     const response = await dispatch(
  //       banoractiveListing({
  //         page: "",
  //         search: "",
  //         limit: "",
  //         banStatus:"1"

  //       })
  //     );
  //     console.log(response,"reeerreredf");

  //     const allData = response?.payload?.data?.banUsers;

  //     const ws = XLSX.utils.json_to_sheet(allData);

  //     const wb = XLSX.utils.book_new();
  //     XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

  //     XLSX.writeFile(wb, "exported_data.xlsx", {
  //       bookType: "xlsx",
  //       bookSST: false,
  //       type: "blob",
  //     });
  //   } catch (error) {
  //     console.error("Error fetching or exporting data:", error);
  //   }
  // };
  const fetchDataAndExportToExcel = async () => {
    try {
      const response = await dispatch(banoractiveListing(userMgmtParams));
      console.log(response?.payload?.data);

      const allData = response?.payload?.data?.banUsers;
      console.log(allData);

      const ws = XLSX.utils.json_to_sheet(allData);

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

      XLSX.writeFile(wb, "exported_data.xlsx", {
        bookType: "xlsx",
        bookSST: false,
        type: "blob",
      });
    } catch (error) {
      console.error("Error fetching or exporting data:", error);
    }
  };

  // const handleselect = (e) => {
  // setUserMgmtParams((old)=>({...old,type:e.target.value}))
  // };

  const handleclick = (id) => {
    console.log(id);
    navigate("/UserProfile", {
      state: {
        id: id,
        //  path:pathFromRedux
      },
    });
  };

  useEffect(() => {
    let fetchdata = async () => {
      if (!initialRender) {
        const filterdata = data?.data?.banUsers?.filter((x) => {
          return (
            x?.createdAt?.slice(0, 10) >= startformattedDate &&
            x?.createdAt?.slice(0, 10) <= endformattedDate
          );
        });

        setFilterDataByDate(filterdata);
      }
      setInitialRender(false);
    };
    fetchdata();
  }, [range, startformattedDate, endformattedDate]);

  const handleDateFieldClick = () => {
    setPickerVisible(true);
  };

  const closerange = () => {
    setPickerVisible(false);
    setRange(!range);
  };

  const getStartDateText = () => {
    return dateRange[0]?.startDate
      ? dateRange[0]?.startDate?.toLocaleDateString()
      : "";
  };

  const todayrange = () => {
    const currentDate = new Date();

    setDateRange([
      {
        startDate: currentDate,
        endDate: currentDate,
        key: "selection",
      },
    ]);
    setRange(!range);
    setPickerVisible(false);
  };

  const yesterdayrange = () => {
    const currentDate = new Date();
    const yesterday = new Date(currentDate);
    yesterday.setDate(currentDate.getDate() - 1);

    setDateRange([
      {
        startDate: yesterday,
        endDate: yesterday,
        key: "selection",
      },
    ]);
    setRange(!range);
    setPickerVisible(false);
  };

  const sevenDaysBefore = () => {
    const currentDate = new Date();
    const sevendays = new Date(currentDate);
    sevendays.setDate(currentDate.getDate() - 7);

    setDateRange([
      {
        startDate: sevendays,
        endDate: currentDate,
        key: "selection",
      },
    ]);
    setRange(!range);
    setPickerVisible(false);
  };
  const handleClose = () => {
    setPickerVisible(false);
    // setDateRange(+("Select range"))
    setDateRange([
      {
        startDate: null,
        endDate: null,
        key: "selection",
      },
    ]);
  };

  // console.log(AllUsers)

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>Active</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={3} md={3}>
                <Form.Group>
                  <Form.Control
                    type="search"
                    name="search"
                    value={userMgmtParams.search}
                    onChange={handlesearch}
                    placeholder="Search.."
                  />
                </Form.Group>
              </Col>

              <Col xxl={3} xl={3} lg={3} md={3}>
                <input
                  type="text"
                  value={`${getStartDateText()}     ${
                    dateRange[0]?.endDate?.toLocaleDateString() ||
                    "Select date range"
                  }`}
                  onClick={handleDateFieldClick}
                  readOnly
                />

                {pickerVisible && (
                  <div
                    className="date-picker"
                    style={{
                      width: "500px",
                      height: "500px",
                      overflow: "scroll",
                      position: "absolute",
                    }}
                  >
                    <div className="d-flex gap-3 justify-content-end m-2 date-range-filters">
                      <Button onClick={handleClose}>Close</Button>
                    </div>
                    <DateRange
                      ranges={dateRange}
                      onChange={handleSelect}
                      months={2}
                      direction="horizontal"
                    />
                    <div className="d-flex gap-3 justify-content-between date-range-filters">
                      <div className="d-flex gap-3">
                        <Button onClick={todayrange}>Today</Button>
                        <Button onClick={yesterdayrange}>Yesterday</Button>
                        <Button onClick={sevenDaysBefore}>before 7 days</Button>
                      </div>
                      <div>
                        <Button onClick={closerange}>Ok</Button>
                      </div>
                    </div>
                  </div>
                )}
              </Col>
              {/* <Col xxl={2} xl={2} lg={3} md={3}>
                <Form.Select aria-label="Default select example">
                  <option>Joining Date</option>
                  <option value="1">10</option>
                  <option value="2">12</option>
                  <option value="3">15</option>
                </Form.Select>
              </Col> */}

              <Col
                xxl={5}
                xl={5}
                lg={3}
                md={3}
                className="d-flex justify-content-end align-items-center"
              >
                <div className="cmn-btn">
                  <Link onClick={fetchDataAndExportToExcel}>DOWNLOAD</Link>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <hr />
        <Container fluid>
          <div className="product-tab">
            <ul>
              <li>
                <Link to="/UserManagement">
                  ALL <span></span>
                </Link>
              </li>
              <li>
                <Link to="/temporarybanned">
                  Banned <span></span>
                </Link>
              </li>
              <li>
                <Link to="/active" className="active-tab">
                  Active <span></span>
                </Link>
              </li>
            </ul>
          </div>
        </Container>
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">
                {" "}
                Showing {1 +
                  (userMgmtParams?.page - 1) * userMgmtParams?.limit}{" "}
                -{" "}
                {data?.data?.banUsers?.length +
                  (userMgmtParams?.page - 1) * userMgmtParams.limit}{" "}
                of {data?.data?.totalUsers} results
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  onChange={handlechange}
                  name="limit"
                  value={userMgmtParams.limit}
                  aria-label="Default select example"
                >
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Name</th>
                <th>Email </th>
                <th>Profile Picture</th>
                <th>Location</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {(filterDataByDate?.length >= 0
                ? filterDataByDate
                : data?.data?.banUsers
              )?.map((item, index) => {
                const serialNumber =
                  (userMgmtParams?.page - 1) * userMgmtParams?.limit +
                  index +
                  1;
                return (
                  <tr>
                    <td>{serialNumber}</td>
                    <td
                      style={{ cursor: "pointer" }}
                      onClick={() => handleclick(item?._id)}
                    >
                      {item?.full_name ? item?.full_name : "N/A"}
                    </td>
                    <td>{item?.email ? item?.email : "N/A"}</td>
                    <td className="user-img">
                      <img
                        src={
                          item?.profile_image
                            ? `https://3ff-api.bosselt.com/${item?.profile_image}`
                            : logo
                        }
                      />
                    </td>
                    <td>{item?.address ? item.address : "N/A"}</td>
                    <td>
                      {item?.status === 0
                        ? "In-active"
                        : item.banStatus === 1
                        ? "Active"
                        : "Suspend"}
                    </td>

                    <td className="select-box">
                      <Form.Select
                        value={item?.banStatus === 0 ? "ban" : "active"}
                        onChange={(e) => handlebanned(item._id, e)}
                        aria-label="Default select example"
                      >
                        <option value="ban">Suspend</option>
                        <option value="active">Active</option>
                      </Form.Select>
                    </td>
                  </tr>
                );
              })}
              {/* <tr>
                <td>1</td>
                <td>
                  <Link to="/UserProfile">John</Link>
                </td>
                <td>John@gmail.com</td>
                <td className="user-img">
                  <img src={require("../Assets/Images/user.png")} />
                </td>
                <td>Berlin, Germany</td>
                <td>Active</td>

                <td className="select-box">
                  <Form.Select aria-label="Default select example">
                    <option>Temporary Banned</option>
                    <option value="1">Active</option>
                  </Form.Select>
                </td>
              </tr>
              <tr>
                <td>2</td>
                <td>
                  <Link to="/UserProfile">John</Link>
                </td>

                <td>John@gmail.com</td>
                <td className="user-img">
                  <img src={require("../Assets/Images/user.png")} />
                </td>
                <td>Berlin, Germany</td>
                <td>Active</td>

                <td className="select-box">
                  <Form.Select aria-label="Default select example">
                    <option>Temporary Banned</option>
                    <option value="1">Active</option>
                  </Form.Select>
                </td>
              </tr>
              <tr>
                <td>3</td>
                <td>
                  <Link to="/UserProfile">John</Link>
                </td>

                <td>John@gmail.com</td>
                <td className="user-img">
                  <img src={require("../Assets/Images/user.png")} />
                </td>
                <td>Berlin, Germany</td>
                <td>Active</td>

                <td className="select-box">
                  <Form.Select aria-label="Default select example">
                    <option>Temporary Banned</option>
                    <option value="1">Active</option>
                  </Form.Select>
                </td>
              </tr>
              <tr>
                <td>4</td>
                <td>
                  <Link to="/UserProfile">John</Link>
                </td>

                <td>John@gmail.com</td>
                <td className="user-img">
                  <img src={require("../Assets/Images/user.png")} />
                </td>
                <td>Berlin, Germany</td>
                <td>Active</td>

                <td className="select-box">
                  <Form.Select aria-label="Default select example">
                    <option>Temporary Banned</option>
                    <option value="1">Active</option>
                  </Form.Select>
                </td>
              </tr>
              <tr>
                <td>5</td>
                <td>
                  <Link to="/UserProfile">John</Link>
                </td>

                <td>John@gmail.com</td>
                <td className="user-img">
                  <img src={require("../Assets/Images/user.png")} />
                </td>
                <td>Berlin, Germany</td>
                <td>Active</td>

                <td className="select-box">
                  <Form.Select aria-label="Default select example">
                    <option>Temporary Banned</option>
                    <option value="1">Active</option>
                  </Form.Select>
                </td>
              </tr>
              <tr>
                <td>6</td>
                <td>
                  <Link to="/UserProfile">John</Link>
                </td>

                <td>John@gmail.com</td>
                <td className="user-img">
                  <img src={require("../Assets/Images/user.png")} />
                </td>
                <td>Berlin, Germany</td>
                <td>Active</td>

                <td className="select-box">
                  <Form.Select aria-label="Default select example">
                    <option>Temporary Banned</option>
                    <option value="1">Active</option>
                  </Form.Select>
                </td>
              </tr>
              <tr>
                <td>7</td>
                <td>
                  <Link to="/UserProfile">John</Link>
                </td>

                <td>John@gmail.com</td>
                <td className="user-img">
                  <img src={require("../Assets/Images/user.png")} />
                </td>
                <td>Berlin, Germany</td>
                <td>Active</td>

                <td className="select-box">
                  <Form.Select aria-label="Default select example">
                    <option>Temporary Banned</option>
                    <option value="1">Active</option>
                  </Form.Select>
                </td>
              </tr>
              <tr>
                <td>8</td>
                <td>
                  <Link to="/UserProfile">John</Link>
                </td>

                <td>John@gmail.com</td>
                <td className="user-img">
                  <img src={require("../Assets/Images/user.png")} />
                </td>
                <td>Berlin, Germany</td>
                <td>Active</td>

                <td className="select-box">
                  <Form.Select aria-label="Default select example">
                    <option>Temporary Banned</option>
                    <option value="1">Active</option>
                  </Form.Select>
                </td>
              </tr> */}
            </tbody>
          </Table>
        </div>
      </Container>
      <Pagination pagination={pagination} paginationevent={handlePages} />
    </Layout>
  );
}
