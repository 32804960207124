

import { createSlice } from "@reduxjs/toolkit";
import { banorUnban, banoractiveListing, userMnagement } from "../actions/adminActions";




export const userMnagementSlice = createSlice({
    name:"userMnagementSlice",
    initialState:{
        allData:"",
        banData:""
    },
    reducers:{
      
    },
    extraReducers:(builder)=>{
        builder.addCase(userMnagement.fulfilled,(state,action)=>{
            state.allData=action.payload
        })
        builder.addCase(banoractiveListing.fulfilled,(state,action)=>{
            
            state.banData=action.payload
        })
    }
    
})

export default userMnagementSlice.reducer
