import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { useLocation } from "react-router-dom";
import { getBlockUserbyid } from "../redux/actions/adminActions";
import { useDispatch } from "react-redux";
import { CloseReport } from "../redux/actions/adminActions";
import { data } from "../Components/Layout/elements/ChartUsers";
import { toast } from "react-toastify";


export default function ManageReport() {

  const [alldata, setalldata] = useState([]);

  const dispatch = useDispatch();

  const location = useLocation();
  console.log(location);


  useEffect(() => {
    const fetchdata =  async(id) => {
      try{
        const data = await dispatch(getBlockUserbyid({ id:location?.state?.id}));
        console.log(data);
        
        setalldata(data?.payload?.data);
      }catch (error){
       console.log(error)
      }
    }
    fetchdata();
  },[])


  const Closereport =  async (status) => {
    try{
      const response = await dispatch(CloseReport({id:location?.state?.id, status: 0 }));
      console.log(response);

      if (response?.payload?.data?.status_code === 200) {
        toast.success(response.payload?.data?.message);
      } else {
        toast.error(response?.payload?.data?.message);
      }

    }catch (error){
     console.log(error)
    }
  }


  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <h2>Report Details</h2>

          <div>
            <hr />
          </div>
        </div>
      </div>
      <Container fluid>
        <div className="product-cmn-tab">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to="/Report">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
      <Container fluid>
        <Row>
          <Col lg={8}>
            <div className="customer-form-new">
              <h2>Report Details</h2>

              <div className="customer-form-inner">
                <Row>
                  <Col lg={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Reported By:</Form.Label>
                      <Form.Control type="text" placeholder="Anna" value={alldata?.data?.blockBy?.full_name}/>
                    </Form.Group>
                  </Col>
                  <Col lg={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Reported To:</Form.Label>
                      <Form.Control type="text" placeholder="Anna" value={alldata?.data?.blockTo?.full_name}/>  
                    </Form.Group>
                  </Col>
                  <Col lg={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Report Reason</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Lorem ipsum dolor sit amet d tempor. "
                        value={alldata?.data?.reason}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Message</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={5}
                        placeholder="Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore mana aliqua. Ut enim ad minim veniam, quis nostrud. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore mana aliqua. Ut enim ad minim veniam, quis nostrud. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore mana aliqua. Ut enim ad minim veniam, quis nostrud. "
                        value={alldata.message}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col lg={4}>
            {" "}
            <div className="customer-form-new px-4 pb-4">
              <h2 className="px-0">Admin Action</h2>
              <button type="button" onClick={Closereport}>Close Ticket</button>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}
