import { createAsyncThunk } from "@reduxjs/toolkit";
import { adminapi } from "../../services/adminapi";
import { toast } from "react-toastify";

const login = createAsyncThunk("login", async (details) => {
  const {data} = await adminapi.post("/adminLogin", details);
  
  if(data?.status_code === 200){
    
    sessionStorage.setItem("token",data?.data?.token)


      return data
  }

  
 

  
});

export { login };
