import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Button } from "react-bootstrap";
import { Allreports } from "../redux/actions/adminActions";
import { useDispatch } from "react-redux";
import Pagination from "../Components/Layout/elements/Pagination";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import { dowanreportUsers } from "../redux/actions/adminActions";

export default function Report() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [flag, setflag] = useState(true);
  const [type,setype] = useState("")

  const [pagination, setpagination] = useState(null);
  // const [userdata, setuserdata] = useState([]);
  // console.log(userdata);
  const [openclosedata, setopenclosedata] = useState([]);
  const [data,setdata] = useState([]);

  const [reportparms, setreportparms] = useState({
    page: 1,
    search: "",
    limit: "10",
    status: 2,
  });

  useEffect(() => {
    const fetchreportdata = async () => {
      try {
        const data = await dispatch(Allreports(reportparms));
        console.log(data);
        // console.log(data?.payload?.data?.data?.Blocks)

        setopenclosedata(data?.payload?.data?.data?.Blocks);
        setdata(data?.payload?.data?.data)
      } catch (error) {
        console.log(error);
      }
    };
    fetchreportdata();
  }, [flag]);

  // const handlechange=(e)=>{
  //   setype(e.target.value)
  // }

  // useEffect(()=>{
    const handlechange = async (e)=> {
      
      // if (type === "2") {
      //   console.log("first");
      //   setflag(!flag);
      // } else {
        try {
          const data = await dispatch(
            Allreports({
              page: 1,
              search: "",
              limit: "10",
              status: e.target.value,
            })
          );
          
          setopenclosedata(data?.payload?.data?.data?.Blocks);
        } catch (error){
          console.log(error)
        }
      }
    // };
// handlechange()  
    
//   },[type])

  const fetchDataAndExportToexcel = async () => {
    try {
      const response = await dispatch(dowanreportUsers());
      console.log(response);

      const allData = response?.payload?.data?.data;
      console.log(allData);

      const ws = XLSX.utils.json_to_sheet(allData);

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

      XLSX.writeFile(wb, "exported_data.xlsx", {
        bookType: "xlsx",
        bookSST: false,
        type: "blob",
      });
    } catch (error) {
      console.error("Error fetching or exporting data:", error);
    }
  };

  useEffect(() => {
    setpagination(
      Math.ceil(
        (reportparms?.search ? 1 : data.totalBlockedUsers) / data?.limit
      )
    );
  }, [data]);

  const handlePages = (page) => {
    setreportparms((old) => ({ ...old, page: page }));
  };

  const ReportBy = (_id) => {
    navigate("/ManageReport", {
      state: {
        id: _id,
      },
    });
  };

  const handlesearch = (e) => {
    setreportparms((old) => ({ ...old, search: e.target.value }));
  };

  

  




  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>All Reports</h2>
        </div>
        <hr />

        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={3} md={3}>
                <Form.Group>
                  <Form.Control
                    type="search"
                    placeholder="Search.."
                    name="search"
                    value={reportparms.search}
                    onChange={handlesearch}
                  />
                </Form.Group>
              </Col>

              <Col xxl={2} xl={2} lg={3} md={3}>
                <Form.Select
                  aria-label="Default select example"
                  onChange={handlechange}
                >
                  <option value="2"> All</option>
                  <option value="1">Open </option>
                  <option value="0">Closed </option>
                </Form.Select>
              </Col>
              <Col
                xxl={7}
                xl={7}
                lg={6}
                md={6}
                className="d-flex justify-content-end align-items-center"
              >
                <div className="cmn-btn">
                  <Link onClick={fetchDataAndExportToexcel}>DOWNLOAD</Link>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <hr />
        <Container fluid>
          <div className="product-tab">
            <ul>
              <li>
                <Link to="" className="active-tab">
                  ALL
                </Link>
              </li>
              {/* <li>
                <Link to="">
                  Open <span> 3,458</span>
                </Link>
              </li>
              <li>
                <Link to="">
                  Closed <span>9,876</span>
                </Link>
              </li> */}
            </ul>
          </div>
        </Container>
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              {/* <div className="show-results">
                {" "}
                Showing {1 +
                  (reportparms?.page - 1) * reportparms?.limit} -{" "}
                {userdata?.Blocks?.length +
                  (reportparms?.page - 1) * reportparms.limit}{" "}
                of{" "}
                {reportparms.search
                  ? userdata?.totalBlockedUsers
                  : userdata?.totalBlockedUsers}{" "}
                results
              </div> */}
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select aria-label="Default select example">
                  <option>10</option>
                  <option value="1">11</option>
                  <option value="2">12</option>
                  <option value="3">13</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Date</th>
                <th>Report By </th>
                <th>Report To </th>
                <th>Reason</th>
                <th>Status </th>
              </tr>
            </thead>
            <tbody>
              {openclosedata
              .map((x, index) => {
                return (
                  <tr>
                    <td key={index}>{index + 1}</td>
                    <td>
                      {(x?.createdAt).slice(0, 10)}
                      <br />
                      <span>{new Date(x?.createdAt).toLocaleTimeString()}</span> 
                    </td>
                    <td>
                      {/* <Link to="/managereport">James</Link> */}
                      <td
                        style={{ cursor: "pointer", fontWeight: "bolder" }}
                        onClick={() => ReportBy(x?._id)}
                      >
                        {x?.blockBy?.full_name}
                      </td>
                    </td>
                    <td>{x?.blockTo?.full_name}</td>
                    <td>{x?.reason}</td>
                    <td>{x?.status === 1 ? "Open" : "Close"}</td>
                  </tr>
                );
              })}
              {/* <tr>
                <td>2</td>
                <td>
                  01/02/2024
                  <br />
                  <span>12:30PM</span>
                </td>
                <td>
                  <Link to="/managereport">James</Link>
                </td>
                <td>Smith</td>
                <td>Lorem Ipsum</td>

                <td>Open</td>
              </tr>
              <tr>
                <td>3</td>
                <td>
                  01/02/2024
                  <br />
                  <span>12:30PM</span>
                </td>
                <td>
                  <Link to="/managereport">James</Link>
                </td>
                <td>Smith</td>
                <td>Lorem Ipsum</td>

                <td>Close</td>
              </tr>
              <tr>
                <td>4</td>
                <td>
                  01/02/2024
                  <br/>
                  <span>12:30PM</span>
                </td>
                <td>
                  <Link to="/managereport">James</Link>
                </td>
                <td>Smith</td>
                <td>Lorem Ipsum</td>
                <td>Open</td>
              </tr>
              <tr>
                <td>5</td>
                <td>
                  01/02/2024
                  <br />
                  <span>12:30PM</span>
                </td>
                <td>
                  <Link to="/managereport">James</Link>
                </td>
                <td>Smith</td>
                <td>Lorem Ipsum</td>
                <td>Close</td>
              </tr>
              <tr>
                <td>6</td>
                <td>
                  01/02/2024
                  <br />
                  <span>12:30PM</span>
                </td>
                <td>
                  <Link to="/managereport">James</Link>
                </td>
                <td>Smith</td>
                <td>Lorem Ipsum</td>
                <td> Open</td>
              </tr>
              <tr>
                <td>7</td>
                <td>
                  01/02/2024
                  <br />
                  <span>12:30PM</span>
                </td>
                <td>
                  <Link to="/managereport">James</Link>
                </td>
                <td>Smith</td>
                <td>Lorem Ipsum</td>
                <td> Open</td>
              </tr>
              <tr>
                <td>8</td>
                <td>
                  01/02/2024
                  <br />
                  <span>12:30PM</span>
                </td>
                <td>
                  <Link to="/managereport">James</Link>
                </td>
                <td>Smith</td>
                <td>Lorem Ipsum</td>
                <td>Close</td>
              </tr>
              <tr>
                <td>9</td>
                <td>
                  01/02/2024
                  <br />
                  <span>12:30PM</span>
                </td>
                <td>
                  <Link to="/managereport">James</Link>
                </td>
                <td>Smith</td>
                <td>Lorem Ipsum</td>
                <td>Open</td>
              </tr>
              <tr>
                <td>10</td>
                <td>
                  01/02/2024
                  <br />
                  <span>12:30PM</span>
                </td>
                <td>
                  <Link to="/managereport">James</Link>
                </td>
                <td>Smith</td>
                <td>Lorem Ipsum</td>
                <td>Close</td>
              </tr> */}
            </tbody>
          </Table>
        </div>
      </Container>

      {/* <div className="next-btn-fix">
        <Link to="#">Next</Link>
      </div> */}
      <Pagination pagination={pagination} paginationevent={handlePages} />
    </Layout>
  );
}
