import React, { useState } from "react";
import Login from "./pages/Login";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoginOtp from "./pages/LoginOtp";
import ForgotPassword from "./pages/ForgotPassword";
import UserManagement from "./pages/UserManagement";

import Support from "./pages/Support";
import Administrators from "./pages/Administrators";

import Dashboard from "./pages/Dashboard";

import Supportchat from "./pages/SupportChat";
import CreateAdmin from "./pages/CreateAdmin";

import Active from "./pages/Active";

import UserProfile from "./pages/UserProfile";

import UserVerification from "./pages/UserVerification";
import Notification from "./pages/Notification";
import ManageReport from "./pages/ManageReport";
import ChangePassword from "./pages/ChangePassword";

import Report from "./pages/Report";
import TemporaryBanned from "./pages/TemporaryBanned";
import ForgotPasswordTwo from "./pages/ForgotPasswordtwo";
import ProfileView from "./pages/ProfileView";
import ProtectedRoutes from "./Components/ProtectedRoutes";
import PrivacyPolicy from "./pages/PrivacyPolicy";

function App() {
  const [address, setAddress] = useState('');

  const getAddressFromCoords = async (lat, long) => {

    const apiKey = 'AIzaSyDK_tFSzkGiL30-mi3gWbRyfVTRkgd3cFg';
    const radius = 10;  // Radius in meters around the specified location

    const apiUrl = `https://maps.googleapis.com/maps/api/place/nearbysearch/json?location=${lat},${lng}&radius=${radius}&key=${apiKey}`;

    try {
      const response = await fetch(apiUrl);
      const data = await response.json();
      console.log(data, "address")

      if (data.status === 'OK') {
        setAddress(data.results[0].formatted_address);
      } else {
        setAddress('Address not found');
      }
    } catch (error) {
      console.error('Error fetching location:', error);
    }
  };

  // Example usage
  const lat = 37.7749; // Example latitude
  const lng = -122.4194; // Example longitude

  // Call the function when needed, like in useEffect or on button click
  getAddressFromCoords(lat, lng);
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route element={<ProtectedRoutes />}>
            <Route path="/Otp" element={<LoginOtp />} />
            <Route path="/Forgot-Password" element={<ForgotPassword />} />
            <Route path="/UserManagement" element={<UserManagement />} />

            <Route path="/Support" element={<Support />} />
            <Route path="/Administrators" element={<Administrators />} />

            <Route path="/Dashboard" element={<Dashboard />} />

            <Route path="/UserProfile" element={<UserProfile />} />

            <Route path="/Supportchat" element={<Supportchat />} />
            <Route path="/Create-Admin" element={<CreateAdmin />} />

            <Route path="/active" element={<Active />} />

            <Route path="/UserVerification" element={<UserVerification />} />
            <Route path="/Notification" element={<Notification />} />
            <Route path="/managereport" element={<ManageReport />} />
            <Route path="/ChangePassword" element={<ChangePassword />} />

            <Route path="/report" element={<Report />} />
            <Route path="/temporarybanned" element={<TemporaryBanned />} />

            <Route path="/change-password" element={<ForgotPasswordTwo />} />
          </Route>
          <Route path="/profile-view/:id" element={<ProfileView />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
