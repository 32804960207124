import axios from "axios";

export const adminapi = axios.create({
  baseURL: process.env.REACT_APP_ADMIN_BASE_URL,
  headers: {
    authorization: sessionStorage.getItem("token"),
  },
});

adminapi.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return error;
  }
);
